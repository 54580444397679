import React from 'react'
import Spacer from "../components/spacer"
import { GatsbyImage } from 'gatsby-plugin-image'

const StoreInfo = ({data}) => {
  var page = data.page
  const onClick = () => {
    document.querySelector('.overlay--gallery').classList.add('active')
  }
  return (
  <div className='p20'>
    <div className='w-70 m-100 text-center mt40'>
      <p className='m0 uppercase small'>(About)</p>
      <p className='m0 h1 mt20 mb40'>Jonson Lane</p>
      <div className='white-space light text-left mt20'> 
        {page.introduction}
      </div>
      <div className='italic white-space text-left mt40 ml40 m-ml0'>
        <span className='ml40'/>{page.quotes}
      </div>
      <Spacer />
      <div className='collaborators light'>
        <p className='m0 uppercase small mb40'>(Collaborators)</p>
        <div className='collabs text-left'>
          {page.collaborator.map((collaborator, index) => {
            return ( 
              <div className='mb40' key={index}>
                <p className='ml40 m0 uppercase small mb20'>{collaborator.title}</p>
                <div className='text white-space'>
                  <span className='ml40'/>{collaborator.text}
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <Spacer />
      <div className='sustainability light'>
        <p className='m0 uppercase small mb40'>(Sustainability)</p>
        <div className='collabs text-left'>
          {page.sustainability.map((sustainability, index) => {
            return ( 
              <div className='mb40'>
                <p className='ml40 m0 uppercase small mb20'>{sustainability.title}</p>
                <div className='text white-space'>
                  <span className='ml40'/>{sustainability.text}
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <Spacer />
      <div className='gallery'>
        <p className='m0 light pointer text-left' role='presentation' onClick={onClick}>See Image Gallery</p>
        <div className='max-225 m-mwa mt10 pointer' role='presentation' onClick={onClick}>
          <div className='bg-grey ratio-1-2 pos-rel'>
            <GatsbyImage className='bg-image' image={page.additionalImages[0]?.gatsbyImageData} alt='Gallery Thumbnail' />
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default StoreInfo
