import * as React from "react"
import { GatsbyImage } from 'gatsby-plugin-image'

const OverlayGallery = ({images}) => {

  var current = 1;
  
  const previousSlide = () => {
    if(current<=1) {
      current = images.length;
    } else {
      current--;
    }
    changeSlide()
  }

  const nextSlide = () => {
    if(current>=images.length) {
      current = 1;
    } else {
      current++;
    }
    changeSlide()
  }

  const changeSlide = () => {
    document.querySelector('.gallery--count').innerHTML = current;
    document.querySelector('.image--gallery .image.active').classList.remove('active');
    document.querySelector('.image--gallery .image:nth-child('+current+')').classList.add('active')
  }

  const closePopup = () => {
    document.querySelector('.overlay--gallery').classList.remove('active')
  }

  return (
  <div className='overlay--gallery'>
    <div className='close' role='presentation' onClick={closePopup}/>
    <div className='image--gallery' data-index='0'>
      {images?.map((image, index) => {
        return ( 
          <GatsbyImage key={index} className={'image '+(index===0 ? 'active':'')} image={image?.gatsbyImageData} alt='Gallery Thumbnail' />
        )
      })}
    </div>
    <p className='previous small uppercase white m0' role='presentation' onClick={previousSlide}>(previous)</p>
    <p className='next white small uppercase m0' role='presentation' onClick={nextSlide}>(next)</p>
    <p className='small index uppercase white m0'><span className='gallery--count'>1</span> Of {images.length}</p>
    <a className='small credit m-hide uppercase white op-50 m0 link' href='https://cieranmurphy.com' target='_blank' rel='noreferrer'>Image Credit: Cieran Murphy</a>
  </div>
  )
}

export default OverlayGallery
