import React, { useEffect } from 'react';
import Layout from "../components/layout"
import AboutIntro from "../components/aboutIntro"
import OverlayGallery from "../components/overlayGallery" 
import Gallery from "../components/gallery" 
import Spacer from "../components/spacer"
import Seo from "../components/seo"
import { graphql } from 'gatsby'

const AboutPage = ({data}) => {
  var page = data.page
  useEffect(() => {
    if(window.location.href.indexOf("#gallery") > -1) {
      setTimeout(function(){
        document.querySelector('.overlay--gallery').classList.add('active')
      }, 500);
    }
  });
  return (
  <Layout>
  <div className='flex m-wrap'>
      <div className='w-50 m-100'>
        <AboutIntro data={data} />
      </div>
      <div className='w-50 m-100 m-hide'>
        <Gallery images={page.gallery} margin={false}/>
      </div>
    </div>
    <Spacer />
    <OverlayGallery images={page.additionalImages} />
  </Layout>
  )
} 


export const Head = () => <Seo title="About" />

export default AboutPage


export const query = graphql`
query AboutQuery {
  page:datoCmsAboutPage {
    introduction
    quotes
    collaborator {
      title
      text
    }
    sustainability {
      title
      text
    }
    gallery {
      gatsbyImageData
    }
    additionalImages {
      gatsbyImageData
    }
  }
}
`